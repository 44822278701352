<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>Korean Society of Head and Neck Surgery</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-70-4108-7957
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          ksthns1990@gmail.com
        </div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>1st Joint Meeting of Tri-HN Society 2022 Secretariat / KEION LTD</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-2-6956-0620
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          tri@kshns.or.kr
        </div>
      </div>
      <div class='flex flex-col lg:justify-start lg:items-start justify-center items-center'>
        <img :src='eventConfigLogoImageUrl'
             class='h-12 cursor-pointer mb-6 w-auto'
             @click='goToHome'/>
        <button
          v-if='hasValidToken' 
          class='text-left uppercase border px-4 py-2 text-center rounded-md hover:shadow-md cursor-pointer'
          @click='logoutApp'>
          logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Koa2022LayoutFooter',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'logout',
      'checkTokenStatus',
    ]),
    logoutApp () {
      this.logout().then(() => {
        console.log('go to login ')
        this.$router.replace({name: 'Login'}).catch(() => {})
      })
    },
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  },
  created () {
    this.checkTokenStatus()
  }
}
</script>
